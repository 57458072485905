import { useState, useEffect } from 'react';
import { Center } from '@mantine/core';
import {
  Container,
  Grid,
  Col,
  ColProps,
  Title,
  Text,
  SimpleGrid,
  Button,
  Group,
  Modal,
  Divider,
} from '@mantine/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {IconBrandTwitter, IconBrandInstagram, IconMail, IconCoffee} from '@tabler/icons-react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { WindupChildren } from "windups";
import './App.css'
import './DarumaDropOne.css'

const Header = () => (
  <Container mt="lg">
    <WindupChildren>
      <Title className="custom-title">Gyuudle's Doodles</Title>
    </WindupChildren>
    {/* <div className="custom-title">ギュウドルのアート</div> */}
  </Container>
);

const socialColors = "#FFB300"
const socialTextColor = "yellow"
const artistName = "Gyuudle"

function Footer() {
  return (
    <Container mt="lg" mb="lg">
      <Text className={"small-text"}>© 2023 {artistName}. All rights reserved. Do not repost art without permission.</Text>
    </Container>
  );
}

const Socials = () => {
  return (
    <Container mt="lg" mb="lg">
      <Center>
        <Group position="center">
          <InstagramButton/>
          <TwitterButton/>
          <KofiButton/>
          <ContactButton/>
        </Group>
      </Center>
    </Container>
  );
};

export function TwitterButton() {
  return (
    <Button
      component="a"
      href="https://twitter.com/gyuudle"
      target="_blank"
      rel="noopener noreferrer"
      color={socialTextColor}
      leftIcon={<IconBrandTwitter color={socialColors} />}
      variant="subtle"
    >
      Twitter
    </Button>
  );
}

export function InstagramButton() {
  return (
    <Button
      component="a"
      href="https://instagram.com/gyuudle"
      target="_blank"
      rel="noopener noreferrer"
      color={socialTextColor}
      leftIcon={<IconBrandInstagram color={socialColors} />}
      variant="subtle"
    >
      Instagram
    </Button>
  );
}

export function KofiButton() {
  return (
    <Button
      component="a"
      href="https://ko-fi.com/gyuudle"
      target="_blank"
      rel="noopener noreferrer"
      color={socialTextColor}
      leftIcon={<IconCoffee color={socialColors} />}
      variant="subtle"
    >
      Ko-Fi
    </Button>
  );
}

export function ContactButton() {
  return (
    <Button
      component="a"
      href="mailto:art@gyuudle.com"
      target="_blank"
      rel="noopener noreferrer"
      color={socialTextColor}
      leftIcon={<IconMail color={socialColors} />}
      variant="subtle"
    >
      Contact Me
    </Button>
  );
}
const Artwork = ({
  src,
  alt,
  caption,
  inverted = true,
  collab_one = '',
  collab_two = '',
  collab_one_url = '',
  collab_two_url = '',
  colProps,
}: {
  src: string;
  alt: string;
  caption: string;
  inverted?: boolean;
  collab_one?: string;
  collab_two?: string;
  collab_one_url?: string;
  collab_two_url?: string;
  colProps: ColProps;
}) => {
  const [hovered, setHovered] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const getImageSrc = (imageIndex: number) => {
    return `artwork/${src}_${imageIndex}.jpg`;
  };

  const getLowImageSrc = (imageIndex: number) => {
    return `artwork/${src}_lq_${imageIndex}.jpg`;
  };

  const openModal = () => {
    setModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  const gridStyle = {
    cursor: 'pointer',
    transform: hovered ? 'scale(1.02)' : 'scale(1)',
    transition: 'transform 0.2s ease-in-out',
  };

  return (
    <Col {...colProps}>
      <div style={{ position: 'relative' }}>
        <div
          style={gridStyle}
          onClick={openModal}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <SimpleGrid cols={2} spacing={0} verticalSpacing={0}>
            {Array.from({ length: 4 }, (_, index) => (
              <div className={inverted ? "image-container" : ""}>
                <LazyLoadImage
                  key={index}
                  height="100%"
                  width="100%"
                  effect="blur"
                  placeholderSrc={getLowImageSrc(index)}
                  src={getImageSrc(index)}
                  alt={alt}
                />
              </div>
            ))}
          </SimpleGrid>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 2,
            pointerEvents: 'auto',
          }}></div>
        </div>
        {hovered && (
          <div>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '8px',
                borderRadius: '4px',
              }}
              onMouseEnter={() => setHovered(true)}
            >
            {caption}
            </div>
            {collab_one.length > 0 && (
              <Button
                component="a"
                style={{
                  position: 'absolute',
                  top: '90%',
                  left: '20%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1,
                  padding: '8px',
                  borderRadius: '4px',
                }}
                color={socialTextColor}
                href={collab_one_url}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHovered(true)}
              >
                {collab_one}
              </Button>
            )}
            {collab_two.length > 0 && (
              <Button
                component="a"
                style={{
                  position: 'absolute',
                  top: '90%',
                  left: '80%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1,
                  padding: '8px',
                  borderRadius: '4px',
                }}
                color={socialTextColor}
                href={collab_two_url}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHovered(true)}
              >
                {collab_two}
              </Button>
            )}
          </div>
        )}

      <Modal
        opened={modalOpen}
        onClose={closeModal}
        size="auto"
      >
        <SimpleGrid cols={2} spacing={0} verticalSpacing={0}>
          {Array.from({ length: 4 }, (_, index) => (
            <div className={inverted ? "image-container" : ""}>
              <LazyLoadImage
                key={index}
                height="100%"
                width="100%"
                effect="blur"
                placeholderSrc={getLowImageSrc(index)}
                src={getImageSrc(index)}
                alt={alt}
              />
            </div>
          ))}
        </SimpleGrid>
        <Footer/>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 2,
          pointerEvents: 'auto',
        }}></div>
      </Modal>
      </div>
    </Col>
  );
};

interface ArtworkType {
  id: number;
  src: string;
  alt: string;
  caption: string;
}

interface CollabType {
  id: number;
  src: string;
  alt: string;
  caption: string;
  collab_one: string;
  collab_one_url: string;
  collab_two: string;
  collab_two_url: string;
}

const Portfolio = () => {
  const [artworks, setArtworks] = useState<ArtworkType[]>([]);
  const [collabs, setCollabs] = useState<CollabType[]>([]);

  useEffect(() => {
    fetch('/artworks.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data: Omit<ArtworkType, 'id'>[]) => {
        const processedData = data.map((item, index) => ({
          id: index + 1,
          ...item,
        }));
        setArtworks(processedData);
      })
      .catch((error) => {
        console.log('Fetch error: ', error);
      });
  }, []);

  useEffect(() => {
    fetch('/collabs.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data: Omit<CollabType, 'id'>[]) => {
        const processedData = data.map((item, index) => ({
          id: index + 1,
          ...item,
        }));
        setCollabs(processedData);
      })
      .catch((error) => {
        console.log('Fetch error: ', error);
      });
  }, []);

  return (
    <Container mt="lg">
      <Grid mt="lg" mb="lg" gutter="lg">
        {artworks.map((artwork) => (
          <Artwork
            key={artwork.id}
            src={artwork.src}
            alt={artwork.alt}
            caption={artwork.caption}
            colProps={{ span: 12, sm: 6, md: 4 }}
          />
        ))}
      </Grid>
      <Divider variant="dotted" color="#FFB300" mt="lg"/>
      <Title className="collab-title">Art Collabs</Title>
      <Grid mt="lg" gutter="lg">
        {collabs.map((artwork) => (
          <Artwork
            key={artwork.id}
            src={artwork.src}
            alt={artwork.alt}
            caption={artwork.caption}
            collab_one={artwork.collab_one}
            collab_one_url={artwork.collab_one_url}
            collab_two={artwork.collab_two}
            collab_two_url={artwork.collab_two_url}
            colProps={{ span: 12, sm: 6, md: 4 }}
          />
        ))}
      </Grid>
    </Container>
  );
};

const App = () => {
  return (
    <div>
      <Header />
      <Socials/>
      <Portfolio />
      <Footer />
    </div>
  );
};

export default App;